import React, { useEffect, useState } from "react";
import "./GraphViewer.css";
import NeoVis, { NEOVIS_ADVANCED_CONFIG } from "neovis.js/dist/neovis.js";
import {
  Alert,
  AlertTitle,
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios_instance from "../../config/axios";

const GraphViewer = ({ inputData }) => {
  const [nodeData, setNodeData] = useState(null);
  const [error, setError] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  // const fetchTagsfromText = async () => {
  //   const data = JSON.stringify({ text: inputData.data });
  //   const res = await axios_instance.post("/text_to_tags", data, {
  //     timeout: 1200000,
  //     headers: {
  //       // Overwrite Axios's automatically set Content-Type
  //       "Content-Type": "application/json",
  //     },
  //   });
  //   return res.data["tags"];
  // };
  // const fetchTagsfromFile = async () => {
  //   const data = new FormData();
  //   data.append("file", this.inputData.data);
  //   const res = await axios_instance.post("/image_to_tags", inputData);
  //   return res.data;
  // };

  // const fetchTags = async () => {
  //   if (inputData.type === "STRING") {
  //     return fetchTagsfromText();
  //   } else {
  //     return fetchTagsfromFile();
  //   }
  // };

  useEffect(() => {
    let viz = null;
    const data = JSON.stringify({ text: inputData.data });
    setFetchingData(true);
    axios_instance
      .post("/text_to_tags", data, {
        timeout: 1200000,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        let tags = res.data["tags"];
        let concepts = [];
        tags.forEach((tag) => {
          concepts.push(tag[0].toUpperCase() + tag.slice(1));
        });
        const cypherQuery =
          "MATCH path=(c:Concept)-[:IS_A*0..5]->(:Concept)-[h:HAS_TAG]-(p:Project) WHERE c.name in ['" +
          concepts.join("','") +
          "']\n" +
          "WITH path, p as project, toFloat(length(path)) as length, toFloat(h.weight) as weight\n" +
          "RETURN path, project, sum(weight/length) as score ORDER BY score desc\n" +
          "LIMIT 10";
        function draw() {
          let config = {
            containerId: "viz",
            neo4j: {
              serverUrl: process.env.REACT_APP_NEO4J_URL,
              serverPassword: process.env.REACT_APP_NEO4J_PASSWORD,
              serverUser: process.env.REACT_APP_NEO4J_USER,
            },
            encrypted:
              window.location.protocol === "https:"
                ? "ENCRYPTION_ON"
                : "ENCRYPTION_OFF",
            labels: {
              Concept: {
                label: "name",
                caption: true,
                community: "description",
                title_properties: ["name", "url"],
                [NEOVIS_ADVANCED_CONFIG]: {
                  static: {
                    shape: "dot",
                    size: 25,
                    font: {
                      background: "none",
                      strokeWidth: "O",
                      size: 25,
                      color: "white",
                    },
                  },
                },
              },
              Project: {
                community: "url",
                label: "name",
                [NEOVIS_ADVANCED_CONFIG]: {
                  static: {
                    color: "#1976d2",
                    shape: "circle",
                    size: 20,
                    font: {
                      background: "none",
                      strokeWidth: "O",
                      size: 30,
                      color: "white",
                    },
                  },
                },
              },
            },
            relationships: {
              HAS_TAG: {
                value: "weight",
              },
              IS_A: {
                value: "weight",
              },
            },
            console_debug: true,
            initialCypher: cypherQuery,
          };

          viz = new NeoVis(config);
          viz.render();
          viz.registerOnEvent("clickNode", (e) => {
            setNodeData(e.node.raw.properties);
          });
          viz.registerOnEvent("error", (e) => {
            setError(true);
          });
          viz.registerOnEvent("completed", (e) => {
            setError(false);
          });
        }

        setFetchingData(false);
        draw();
      })
      .catch((e) => {
        console.log(e);
        setFetchingData(false);
        setError(true);
      });
  }, [inputData.data]);

  return (
    <div className={"neovis-container"}>
      {fetchingData && (
        <div className={"neovis-status-container"}>
          <CircularProgress />
        </div>
      )}
      {error && (
        <div className={"neovis-status-container"}>
          <Alert severity={"error"}>
            <AlertTitle>
              <strong>An error occurred...</strong>
            </AlertTitle>
            Try to disconnect the VPN or retry later
          </Alert>
        </div>
      )}
      <div
        id={"viz"}
        className={!fetchingData && !error ? "neovis-status-container" : ""}
      />
      <div className={"tooltip-container"}>
        {nodeData ? (
          <TableContainer sx={{ height: "100%" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={"tooltip-data"}>
                    <Typography variant={"body2"} sx={{ fontWeight: "bold" }}>
                      Field
                    </Typography>
                  </TableCell>
                  <TableCell className={"tooltip-data"}>
                    <Typography variant={"body2"} sx={{ fontWeight: "bold" }}>
                      Value
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(nodeData).map(([key, value]) => {
                  return (
                    <TableRow key={key}>
                      <TableCell className={"tooltip-data"}>
                        <Typography
                          variant={"body2"}
                          sx={{ fontWeight: "bold", color: "white" }}
                        >
                          {key}
                        </Typography>
                      </TableCell>
                      <TableCell className={"tooltip-data"}>
                        {(value + "").includes("http") ? (
                          <Link
                            href={value}
                            target={"_blank"}
                            sx={{ color: "white" }}
                          >
                            {value}
                          </Link>
                        ) : (
                          <Typography variant={"body2"} sx={{ color: "white" }}>
                            {value}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className={"tooltip-no-data"}>
            <Typography justifyContent="center" align="center">
              Click on a node to display its data.
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default GraphViewer;
