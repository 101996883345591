import React from "react";
import "./Navabar.css";
import { AppBar, Button, Toolbar } from "@mui/material";
import logo from "../../assets/images/micras-low-resolution-logo-color-on-transparent-background.png";
import { NavLink } from "react-router-dom";
import MobileMenu from "./Mobile/MobileMenu";

const Navbar = () => {
  return (
    <AppBar position="static" sx={{ bgcolor: "#171933" }}>
      <Toolbar>
        <div className={"navbar"}>
          <NavLink
            to={"/"}
            exact={"true"}
            children={() => <img src={logo} alt="Logo MICRAS" width="128" />}
          />
          {navigator.userAgent.includes("Mobile") ? (
            <MobileMenu />
          ) : (
            <div>
              <NavLink
                to={"/request"}
                children={({ isActive }) => (
                  <Button
                    variant={isActive ? "outlined" : ""}
                    className={
                      isActive
                        ? "navbar-link navbar-link-active"
                        : "navbar-link"
                    }
                    sx={{ color: "white" }}
                  >
                    Request
                  </Button>
                )}
              />
            </div>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
