import React from "react";
import "./Main.css";
import StepperGraph from "../Stepper/StepperGraph";

const Main = () => {
  return (
    <div className={"main-container"}>
      <StepperGraph />
    </div>
  );
};

export default Main;
