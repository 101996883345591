import React, { useState } from "react";
import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import iaIconWhite from "../../../assets/ia-icon-white.png";
import { NavLink } from "react-router-dom";
import "./MobileMenu.css";
import MenuIcon from "@mui/icons-material/Menu";

const MobileMenu = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleOpenDrawer = (e) => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <div>
      <Button onClick={toggleOpenDrawer}>
        <MenuIcon sx={{ color: "white" }} />
      </Button>
      <Drawer anchor={"right"} open={openDrawer} onClose={toggleOpenDrawer}>
        <Box
          role="presentation"
          onClick={toggleOpenDrawer}
          className={"drawer"}
        >
          <Toolbar />

          <List>
            <NavLink
              to={"/request"}
              children={({ isActive }) => (
                <ListItem
                  className={
                    isActive ? "navbar-link navbar-link-active" : "navbar-link"
                  }
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <img
                        src={iaIconWhite}
                        width={30}
                        alt={"icon IA Request"}
                      />
                    </ListItemIcon>
                    <ListItemText primary={"Request"} />
                  </ListItemButton>
                </ListItem>
              )}
            />
          </List>
        </Box>
      </Drawer>
    </div>
  );
};

export default MobileMenu;
