import React, { useState } from "react";
import "./StepperGraph.css";
import { Button, Step, StepLabel, Stepper } from "@mui/material";
import ProjectForm from "../Form/ProjectForm";
import GraphViewer from "../GraphViewer/GraphViewer";

const StepperGraph = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [inputData, setInputData] = useState({ type: null, data: null });

  const STEPS = ["Project description", "Visualization"];

  const handleNextStep = (e) => {
    setActiveStep(activeStep + 1);
  };

  const handleBackStep = (e) => {
    setActiveStep(activeStep - 1);
  };

  return (
    <div className={"stepper-container"}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {STEPS.map((label) => (
          <Step key={label}>
            <StepLabel sx={{ color: "white" }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === 0 ? (
        <ProjectForm inputData={inputData} setInputData={setInputData} />
      ) : (
        <GraphViewer inputData={inputData} />
      )}

      <div className={"stepper-actions"}>
        <Button
          disabled={activeStep === 0}
          variant={"contained"}
          onClick={handleBackStep}
        >
          Back
        </Button>
        <Button
          disabled={activeStep !== 0}
          variant={"contained"}
          onClick={handleNextStep}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default StepperGraph;
