import Navbar from "./components/Navabar/Navbar";
import "./App.css";
import Main from "./components/Main/Main";
import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import particuleConfig from "./config/particules_config.json";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";

function App() {
  const particlesInit = useCallback(async (engine) => {
    // console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    // await console.log(container);
  }, []);

  return (
    <div className="App">
      <Particles
        id="tsparticules"
        init={particlesInit}
        loaded={particlesLoaded}
        options={particuleConfig}
      />
      <Navbar />
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={"/request"} element={<Main />} />
        <Route path={"*"} element={<h1> Error</h1>} />
      </Routes>
    </div>
  );
}

export default App;
