import React from "react";
import "./Card.css";
import { Button, Typography } from "@mui/material";
import { InsertDriveFile, PhotoSizeSelectActual } from "@mui/icons-material";

const Card = ({ files, type, setState }) => {
  const resetStateFiles = () => setState({ type: null, data: null });

  return (
    <div>
      <div className={"header"}>
        <Typography sx={{ color: "white", marginRight: "10px" }}>
          {files.length} file(s) selected :
        </Typography>
        <Button size="small" variant="outlined" onClick={resetStateFiles}>
          Clear
        </Button>
      </div>
      <div className={"card-list-container"}>
        {files.map((file, index) => (
          <div key={index} className={"card-container"}>
            <div className={"card-image"}>
              {type === "PHOTOS" ? (
                <PhotoSizeSelectActual sx={{ fontSize: 50 }} />
              ) : (
                <InsertDriveFile sx={{ fontSize: 50 }} />
              )}
            </div>
            <Typography
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "center",
              }}
            >
              {file.name}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Card;
