import React from "react";
import { Typography } from "@mui/material";

const Home = () => {
  return (
    <Typography variant={"h6"} align="center" sx={{ color: "white" }}>
      WELCOME
    </Typography>
  );
};

export default Home;
