import React from "react";
import "./ProjectForm.css";
import { Button, TextField, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { PhotoCamera } from "@mui/icons-material";
import Card from "../Card/Card";

const ProjectForm = ({ inputData, setInputData }) => {
  const handleFileChange = (e) =>
    setInputData({ type: "FILE", data: Array.from(e.target.files) });
  const handlePhotosChange = (e) =>
    setInputData({ type: "PHOTOS", data: Array.from(e.target.files) });

  const handleChange = (e) =>
    setInputData({ type: "STRING", data: e.target.value });

  return (
    <div>
      <div className={"input-element"}>
        <Button
          fullWidth
          startIcon={<AddIcon />}
          variant="contained"
          component="label"
        >
          <Typography>Upload File</Typography>
          <input
            type="file"
            onChange={handleFileChange}
            hidden
            accept={"application/pdf"}
          />
        </Button>
        {navigator.userAgent.includes("Mobile") && (
          <Button
            variant="outlined"
            sx={{ borderColor: "white", marginTop: "10px" }}
            component="label"
          >
            <PhotoCamera sx={{ color: "white" }} />
            <input
              type="file"
              onChange={handlePhotosChange}
              hidden
              accept="image/*"
              capture="environment"
            />
          </Button>
        )}
      </div>
      {inputData.data && inputData.type !== "STRING" && (
        <Card
          files={inputData.data}
          type={inputData.type}
          setState={setInputData}
        />
      )}
      <TextField
        fullWidth
        multiline
        rows={10}
        label={"Your project description"}
        value={inputData.type === "STRING" ? inputData.data : ""}
        onChange={handleChange}
        sx={{
          bgcolor: "white",
          borderRadius: 1,
        }}
      />
    </div>
  );
};

export default ProjectForm;
